.g-modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: flex-end;
  z-index: 999;
  visibility: hidden;

  &.visible {
    visibility: visible;

    & .modal {
      opacity: 1;
      right: 0px;
    }
  }

  & .modal {
    width: 33%;
    max-width: 450px;
    height: 100%;
    background-color: #fcfcfe;
    border-left: 1px solid #dcdcdc;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    position: relative;
    right: -33%;
    padding: 0rem;

    transition: right 0.2s linear;

    @media (max-width: $responsive-width) {
      width: 100%;
      max-width: 100%;
    }

    & .modal-close {
      display: none;
      background-image: url('../images/close.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 17px;
      height: 17px;
      position: absolute;
      top: 2.5em;
      right: 1.5em;
    }

    & .modal-header {
      text-align: left;
      padding: 2.5em 1.5em 2em 1.5em;
      // border-bottom: 1px solid #dcdcdc;

      // display: flex;
      // align-items: center;
      & .header-text-small {
        text-transform: uppercase;
        display: inline-block;
        font-size: 0.9em;
        font-weight: 500;
        color: #ccc;
        margin-bottom: 16px;

        &.link {
          color: $brand-green;
        }
      }

      & .header-text-large {
        margin-top: 4px;
        display: block;
        font-size: 1.6em;
        font-weight: 900;
        color: #4f4f4f;
      }
    }

    & .modal-content {
      padding: 1.5em;
      margin-bottom: 0rem;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;

      h2 {
        font-family: 'Avenir';
        color: $text-normal;
        font-size: 1.3em;
        padding-top: 0;
        margin: 0;
        text-align: left;
        margin-bottom: 16px;
        display: block;
      }

      h3.header {
        font-family: 'Avenir';
        color: $text-normal;
        font-size: 1.1em;
        padding-top: 0;
        margin: 0;
        text-align: left;
        margin-bottom: 12px;
        margin-top: 16px;
        display: block;
      }

      #grouped-modal-detail-view {
        .grouped-transaction-details {
          &.option {
            margin-bottom: 0;
          }
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 1rem;
          p {
            margin-bottom: 0;
          }
          p.date-payee,
          span {
            font-family: 'Inconsolata', monospace;
            font-size: 1.1rem;
          }
          p.notes {
            font-family: 'Avenir', sans-serif;
            font-size: 0.9rem;
          }
        }
      }

      #transactions-modal-detail-view,
      #recurring-modal-detail-view,
      #grouped-modal-detail-view,
      #create-transfer-view,
      #split-view {
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;

        .ui.checkbox {
          &.checked label {
            color: #444;
          }
          label {
            font-size: 0.9em;
            color: #aaa;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 4px;
          }
        }

        .asset-dropdown-option {
          display: flex;
          justify-content: space-between;
          p {
            margin-bottom: 0;
          }
          p.option-header,
          span {
            font-family: 'Inconsolata', monospace;
            font-size: 1rem;
          }
          p.option-subheader {
            font-family: 'Avenir', sans-serif;
            font-size: 0.9rem;
          }
        }

        .transaction-details {
          background-color: #fff;
          border: 1px solid #e5e5e5;
          padding: 1em 1em 0 1em;
          border-radius: 4px;
          margin-bottom: 24px;

          &.padded {
            padding: 1em !important;
          }

          &.color-border-0 {
            border-color: #d09292;
            label {
              color: #d09292 !important;
            }
          }

          &.color-border-1 {
            border-color: #94abd0;
            label {
              color: #94abd0 !important;
            }
          }

          &.color-border-2 {
            border-color: #97d094;
            label {
              color: #97d094 !important;
            }
          }

          // For files
          .drag-over {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border: 10px dashed #eee;
            z-index: 10000;
            // display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.8);
            display: none;

            & > * {
              pointer-events: none;
            }

            &.show {
              display: flex;
            }
          }

          &.important {
            border-color: $brand-yellow;
          }
          &.warning {
            border-color: $red;
          }

          &.selected {
            border-color: $brand-green;

            h3 {
              color: $brand-green;
            }
            .icon {
              color: $brand-green !important;
            }
          }

          .scrollable-list {
            max-height: 150px;
            overflow-y: auto;
          }

          h3 {
            color: #aaa;
            font-weight: 600;
            text-transform: uppercase;
            font-family: 'Avenir';
            border-bottom: 1px solid #ccc;
            padding-bottom: 0.6em;
            font-size: 1em;
            // text-transform: uppercase;
            align-items: flex-start !important;

            span {
              font-family: 'Inconsolata';
              font-size: 1em;
              font-weight: bold;
              text-transform: none;
              top: -1px;
              position: relative;
            }
          }

          &.clickable.icon {
            position: relative;
            top: -2px;
            color: #e5e5e5 !important;
          }

          &.clickable:hover {
            border-color: $brand-green;
            h3 {
              color: $brand-green;

              .icon {
                color: $brand-green !important;
              }
            }
          }
        }

        label {
          font-size: 0.9em;
          font-family: 'Avenir';
          color: #bbb;
          text-transform: uppercase;
          font-weight: 600;
        }

        .transaction-detail {
          margin-bottom: 12px;

          span:not(.sizer):not(.text) {
            margin-top: 1px;
            display: block;
            font-family: 'Inconsolata';
            font-size: 1.1em;
            color: $text-normal;
          }

          & .name {
            font-size: 1.3em;
            font-weight: 600;
          }
        }
      }

      .detail-buttons {
        margin-bottom: 24px;

        button {
          margin-bottom: 12px;
        }
      }

      /* dropdown */
      .ui.dropdown {
        font-family: 'Inconsolata';
      }
    }

    & .modal-footer {
      margin: 1.5em;
      padding: 1.5em 0em 0em 0em;
      border-top: 1px solid #dcdcdc;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        padding: 0 0.5em;
        color: #ccc;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.9em;
      }
    }
  }

  /**
   * Editing transactions
   */

  .modal-split-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 1em;
    background-color: #fff;

    label {
      font-size: 0.9em;
      color: #aaa;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .modal-split-row-upper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;

      .modal-split-category {
        flex-grow: 1;
        margin-right: 4px;
      }
      .modal-split-amount {
        width: 200px;
      }
    }

    .modal-split-row-lower {
      display: flex;
      align-items: center;
      .modal-split-notes {
        flex-grow: 1;
      }
      .modal-split-x {
        padding: 0 4px;
        flex-shrink: 1;
      }
    }
  }

  .split-amount-left {
    text-align: center;
    display: block;
    margin-bottom: 1em;
    padding: 0.5em;
  }
}

.grouped-tx-row {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 4px;
  max-width: 100%;
  padding: 0.7em 1em 0.5em 1em;
  margin-bottom: 0.7em;
  color: #919191;

  .grouped-tx-content {
    width: 75%;
  }

  .grouped-tx-side {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    text-align: right;

    .monospace {
      position: relative;
      top: -1px;
    }
  }

  .show-on-hover {
    display: none;
  }
  &:hover {
    .show-on-hover {
      display: block;
    }
  }

  .account-name {
    font-family: 'Avenir';
    font-size: 0.85rem;
    text-transform: uppercase;
  }
}

.help-row {
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  max-width: 100%;
  padding: 0.7em 0.8em 0.7em 1em;
  margin-bottom: 0.7em;
  color: #666;

  span {
    padding-top: 3px;
    font-size: 1.2rem;
  }

  span.smaller {
    font-size: 1rem;
  }

  &.disabled {
    color: #e5e5e5;
    border-color: #e5e5e5;
  }

  &.active {
    color: $brand-green;
    border-color: $brand-green;
    font-weight: bold;
  }

  .icon {
    margin-right: 1rem;
    color: #666;
  }

  &:not(.disabled):not(.active):not(.pending):hover {
    cursor: pointer;
    border: 1px solid $brand-green;
    color: $brand-green;
    .icon {
      color: $brand-green;
    }
  }
}

.link-recurring-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 0.7em 0.8em 0.7em 1em;
  margin-bottom: 0.7em;
  color: #919191;

  &:not(.half) {
    max-width: 100%;
  }
  &.half {
    width: 49%;
    &:nth-child(odd) {
      margin-right: 0.5em;
    }
  }

  p:not(.text),
  span:not(.text) {
    display: block;
    margin-right: 1em;
    margin-bottom: 0;
    flex-grow: 1;
    font-size: 1.1em;
    font-family: 'Inconsolata';

    &.normal-font {
      font-weight: 500;
      font-family: 'Avenir' !important;
    }
  }

  .ui.dropdown > .menu > .header {
    font-family: 'Avenir';
    font-size: 0.85rem;
  }

  .icon {
    color: #e5e5e5;
  }

  &.disabled {
    color: #d5d5d5;
    border-color: #d5d5d5;
  }

  &.selected {
    border: 1px solid $brand-green;
    color: $brand-green;

    .icon {
      color: $brand-green;
    }
  }
  &:hover:not(.disabled) {
    cursor: pointer;
  }
  &:hover:not(.selected):not(.disabled) {
    border: 1px solid $brand-green;
    color: $brand-green;
    .icon {
      color: $brand-green;
    }
  }
}
